
(() => {
  const upArrow = 38
  const downArrow = 40

  const trapArrowKeys = (e) => {
    if (e.which === upArrow || e.which === downArrow) {
      e.preventDefault()
    }
  }

  const disableNumberFieldArrows = () => {
    document
      .querySelectorAll('input[type=number]')
      .forEach(input => {
        input.addEventListener('keydown', trapArrowKeys)
      })
  }

  document.addEventListener('turbolinks:load', disableNumberFieldArrows)
})();
